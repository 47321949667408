<template>
  <body>
    <div v-if="!validacion && mensaje_error != null">
      <v-row>
        <div class="titulo">
          <Titulo Titulo="Recibo No Generado" />
        </div>
        <div class="atras">
          <router-link to="/paso6">
            <Back />
          </router-link>
        </div>
      </v-row>
      <div class="col-lg-12" style="color: #D21E27 !important; font-size: 20px; align-items: center;">
          {{ mensaje_error }}
          <br><br><br>
        </div>
    </div>
    <v-row v-if="validacion">
      <div class="titulo">
        <Titulo Titulo="Recibo Generado" />
      </div>
      <!--
      <div class="atras">
        <router-link to="/paso6">
          <Back />
        </router-link>
      </div>
      -->
    </v-row>
  <div v-if="validacion" class="container whitepaper">
    <div id="recibofinal">
      <div v-if="cargando" class="spinner-container">
        <div class="spinner"></div>
        <p class="spinner-text">Obteniendo recibo...</p>
      </div>
      <div v-if="!cargando" class="row">
        <div class="Header">
          <div v-if="linea === 1" class="Logo_empresa">
            <img src="/images/logoapolo.png" alt="Logo" />
          </div>
          <div v-else class="Logo_empresa">
            <img src="/images/logoline.png" alt="Logo" />
          </div>
          <div class="Logo_paynet">
            <div>Servicio a pagar</div>
            <img src="/images/paynet_logo.png" alt="Logo Paynet" />
          </div>
        </div>
        <div class="col-lg-2"></div>
        <div class="col-lg-8" style="color: #D21E27 !important; font-size: 20px; align-items: center;">
          Este boleto tambien podrá ser pagado directamente en taquilla y tiene un lapso hasta de {{ horas_eliminar_reserva }} hrs antes de su salida para que la reserva se mantenga si no será borrada automáticamente.
          <br><br><br>
        </div>
        <div class="col-lg-2"></div>
        <div class="Data">
          <div class="Big_Bullet">
            <span></span>
          </div>
          <div class="col1">
            <b><h3>Fecha límite de pago</h3></b>
            <b
              ><h4>{{ fecha_vencimiento }}</h4></b
            >
            <img width="300" :src="imagen" alt="Código de Barras" />
            <div v-if="referencia">
              <center>
                <span>{{ referencia }}</span>
              </center>
            </div>

            <div class="escaner">
              <small
                >En caso de que el escáner no sea capaz de leer el código de
                barras, escribir la referencia tal como se muestra.</small
              >
            </div>
          </div>
          <div class="col2">
            <div class="total">
              <h2>Total a pagar</h2>
            </div>
            <div class="precio" v-if="monto">
              <h1>${{ monto }}<span></span><small> MXN</small></h1>
            </div>
            <div class="comision">
              <span class="note"
                >La comisión por recepción del pago varía de acuerdo a los
                términos y condiciones que cada cadena comercial
                establece.</span
              >
            </div>
          </div>
        </div>
        <div class="DT-margin"></div>
        <div class="Data">
          <div class="Big_Bullet">
            <span></span>
          </div>
          <div class="col1">
            <b><h3>Detalles de la compra</h3></b>
          </div>
        </div>
        <div class="Table-Data">
          <div class="table-row color1">
            <div>Descripción</div>
            <span>Realiza tu pago de la reserva de boletos(s): {{ boletos_reservados_string }}</span>
          </div>
          <div class="table-row color2">
            <div>Fecha y hora</div>
            <span>{{ fecha_creacion }}</span>
          </div>
          <div class="table-row color1">
            <div>Correo del cliente</div>
            <span>{{ this.email }}</span>
          </div>
          <div class="table-row color2" style="display: none">
            <div>&nbsp;</div>
            <span>&nbsp;</span>
          </div>
          <div class="table-row color1" style="display: none">
            <div>&nbsp;</div>
            <span>&nbsp;</span>
          </div>
        </div>
        <div class="DT-margin"></div>
        <div>
          <div class="Big_Bullet">
            <span></span>
          </div>
          <div class="col1">
            <b> <h3>Como realizar el pago</h3></b>
            <div class="realizarPago">
              <ol>
                <li>1. Acude a cualquier tienda afiliada</li>
                <li>
                  2. Entrega al cajero el código de barras y menciona que
                  realizarás un pago de servicio Paynet
                </li>
                <li>3. Realizar el pago en efectivo por ${{ monto }}</li>
                <li>4. Conserva el ticket para cualquier aclaración</li>
              </ol>
              <small
                >Si tienes dudas comunícate a NOMBRE TIENDA al teléfono TELEFONO
                TIENDA o al correo CORREO SOPORTE TIENDA</small
              >
            </div>
          </div>
          <div class="col1">
            <b><h3>Instrucciones para el cajero</h3></b>
            <div class="cajero">
              <ol>
                <li>1. Ingresar al menú de Pago de Servicios</li>
                <li>2. Seleccionar Paynet</li>
                <li>
                  3. Escanear el código de barras o ingresar el núm. de
                  referencia
                </li>
                <li>4. Ingresa la cantidad total a pagar</li>
                <li>5. Cobrar al cliente el monto total más la comisión</li>
                <li>
                  6. Confirmar la transacción y entregar el ticket al cliente
                </li>
              </ol>
              <small
                >Para cualquier duda sobre como cobrar, por favor llamar al
                teléfono +52 (55) 5351 7371 en un horario de 8am a 9pm de lunes
                a domingo</small
              >
            </div>
          </div>
        </div>

        <div class="row logos-tiendas">
          <ul>
            <li><img src="/images/01.png" width="100" height="50" /></li>
            <li><img src="/images/02.png" width="100" height="50" /></li>
            <li><img src="/images/03.png" width="100" height="50" /></li>
            <li><img src="/images/04.png" width="100" height="50" /></li>
            <li><img src="/images/05.png" width="100" height="50" /></li>
            <li><img src="/images/06.png" width="100" height="50" /></li>
            <li><img src="/images/07.png" width="100" height="50" /></li>
            <li><img src="/images/08.png" width="100" height="50" /></li>
          </ul>
          <div class="tienda">
            ¿Quieres pagar en otras tiendas? visítanos en:
            www.openpay.mx/tiendas
          </div>
        </div>
      </div>
    </div>
        <div class="Powered">
          <img
            src="/images/powered_openpay.png"
            alt="Powered by Openpay"
            width="150"
          />
          <a @click="print()">Imprimir</a>
          <a href="/"
            >Seguir comprando</a
          >
        </div>

        <div style="height: 40px; width: 100%; float: left;"></div>
      </div>
  </body>
</template>
<script>
import axios from "axios";
import CryptoJS from "crypto-js";
import Back from "@/components/Back.vue";
import Titulo from "@/components/title.vue";
import { Printd } from 'printd';

export default {
  props:{
    boletos_ida: { required: false },
    boletos_vuelta: { required: false }
  },
  components: {
    Back, Titulo
  },
  computed: {
    boletos_combinados() {
      const boletos = JSON.parse(this.boletos_ida);
      const boletos_vuelta = JSON.parse(this.boletos_vuelta);
      return boletos.concat(boletos_vuelta);
    },
    set() {
      
    }
  },
  data: () => ({
    linea: null,
    validacion: false,
    mensaje_error: null,
    valid: true,
    nombre: "",
    apellidos: "",
    email: localStorage.getItem("email"),
    telefono: "",
    imagen: null,
    boleto: '',
    cargando: true,
    deviceSessionId: "",
    referencia: null,
    monto: null,
    fecha_vencimiento: null,
    fecha_creacion: null,
    transaccion_plataforma_id: "",
    openpay_id: process.env.VUE_APP_OPENPAY_ID,
    openpay_key: process.env.VUE_APP_OPENPAY_KEY,
    openpay_sandbox_mode: process.env.VUE_APP_OPENPAY_SANDBOX_MODE, 
    functionHasRun: false,
    horas_eliminar_reserva: null,
    boletos_reservados: [],
    boletos_reservados_string: null,
    cssText: `
      h3 {
        font-size: 25px;
        margin: 2px 0 0 0;
      }
      h4 {
        font-size: 17px;
        margin: 4px 0;
      }
      .whitepaper {
        background-color: #fff;
        width: 812px;
        height: 1483px;
        margin: 0 auto;
        border: #d8d8d8 1px solid;
      }
      .Header {
        clear: both;
        float: left;
        width: 84%;
        margin: 2% 8% 1% 8%;
      }
      .Logo_empresa img {
        width: 190px;
        float: left;
      }
      .Logo_paynet {
        float: right;
        margin-top: 3px;
      }
      .Logo_paynet div {
        font-size: 20px;
        font-weight: lighter;
        display: block;
        float: left;
        margin: 10px 12px 0 0;
      }
      .Logo_paynet img {
        width: 130px;
        float: left;
      }
      .Data {
        width: 100%;
        clear: both;
        float: left;
      }
      .DT-margin {
        margin: 5px 0;
        display: block;
        float: left;
        width: 100%;
        clear: both;
      }
      .Big_Bullet {
        width: 40px;
        float: left;
        margin-right: 24px;
      }
      .Big_Bullet span,
      .col2 {
        background-color: #d31e26;
      }
      .Big_Bullet span {
        width: 100%;
        height: 55px;
        display: block;
      }
      .col1 {
        width: 350px;
        float: left;
      }
      .col1 b h3{
        margin-bottom: 2px;
        margin-top: 5px;
      }
      .col1 span {
        font-size: 14px;
        clear: both;
        display: block;
        margin: 5px 0;
      }
      .col1 small {
        font-size: 12px;
        width: 320px;
        display: block;
      }
      .col2 {
        width: 358px;
        float: right;
        color: #fff;
        padding: 20px 0 20px 40px;
      }
      .col2 h1 {
        margin: 0;
        padding: 0;
        font-size: 60px;
      }
      .col2 h1 span {
        font-size: 45px;
      }
      .col2 h1 small {
        font-size: 20px;
      }
      .col2 h2 {
        margin: 0;
        font-size: 22px;
        font-weight: lighter;
      }
      .S-margin {
        padding-left: 80px;
      }

      .Table-Data {
        margin: 8px 0 0 0;
        clear: both;
        width: 107.5%;
        display: block;
        float: left;
      }
      .table-row {
        float: left;
        width: 93%;
        padding: 0 8.5%;
      }
      .table-row div {
        font-size: 18px;
        float: left;
        width: 180px;
        padding: 15px 0;
      }
      .table-row span {
        font-size: 18px;
        float: left;
        border-left: 3px solid #fff;
        padding: 15px 0 15px 30px;
      }
      .color1 {
        background-color: #f3f3f3;
      }
      .color2 {
        background-color: #ebebeb;
      }

      .col1 ol,
      .Col2 ol {
        font-size: 12px;
        width: 290px;
        padding-left: 20px;
      }
      .col1 li,
      .Col2 li {
        padding: 5px 0;
        line-height: 16px;
      }
      .logos-tiendas {
        clear: both;
        float: left;
        width: 92%;
        padding: 2px 0 10px 8%;
        border-top: 1px solid #ededed;
        border-bottom: 1px solid #ededed;
        margin: 5px 0 0 0;
      }
      .logos-tiendas div {
        float: left;
        margin-right: 50px;
      }
      .logos-tiendas small {
        font-size: 11px;
        margin-left: 20px;
        float: left;
      }
      .logos-tiendas ul {
        margin: 0;
        list-style: none;
        padding: 0;
        width: 480px;
        float: left;
      }
      .logos-tiendas li {
        float: left;
        margin: 2px 10px 0 10px;
      }

      .Powered {
        width: 100%;
        float: left;
        margin-top: 8px;
      }
      .Powered img {
        margin-left: 65px;
        margin-right: 290px;
      }
      .Powered a {
        border-radius: 6px;
        background-color: #0075f0;
        padding: 2px 13px;
        color: #fff;
        font-size: 16px;
        font-weight: normal;
        text-decoration: none;
        margin: 2px;
      }
      .Powered a:hover {
        background-color: #009bff;
      }

      .note {
        font-size: 12px;
      }
      .tienda {
        font-weight: 600;
        height: 90px;
        width: 190px;
        float: right;
        margin-top: 25px;
        margin-left: 10px;
        font-size: 16px;
        line-height: 22px;
      }
      .escaner {
        line-height: 20px;
      }
      .comision {
        text-align: left;
        font-size: 14px;
        line-height: 18px;
      }
      .total {
        margin-left: -5%;
      }
      .precio {
        margin-top: 5%;
      }
      .realizarPago {
        font-weight: 600;
        text-align: left;
        line-height: 18px;
      }
      .cajero {
        font-weight: 600;
        text-align: left;
        line-height: 18px;
      }
      .spinner-container {
        text-align: center;
      }
      .spinner {
        width: 50px;
        height: 50px;
        border: 8px solid #f3f3f3;
        border-top: 8px solid #3498db;
        border-radius: 50%;
        animation: spin 0.8s linear infinite;
        margin-left: 45%;
        margin-top: 40%;
      }
      .spinner-text {
        margin: 20px 0;
        font-size: 20px;
        color: #333;
      }`,
    
  }),
  created(){
    this.boleto = JSON.parse(this.boletos_ida);
  },
  async mounted() {
    Promise.all([this.iniciar(), this.obtenerReferencia(), this.getOpciones()]).then(
      function () {}
    );
  },
  methods: {
    print(){
      const d = new Printd()
      d.print( document.getElementById('recibofinal'), [this.cssText])
    },
    async getOpciones(){
      const options = {
        headers: {
          Accept: "application/vnd.api+json",
          "Content-Type": "application/vnd.api+json"
        },
      };
      axios.get(`/opciones`, options).then((response) => {
        this.horas_eliminar_reserva = response.data.data[4].attributes.valor;
        
      });
    },
    async iniciar() {
      // sistema de fraude
      OpenPay.setId(this.openpay_id);
      OpenPay.setApiKey(this.openpay_key);
      //sOpenPay.setSandboxMode(this.openpay_sandbox_mode);
      this.deviceSessionId = OpenPay.deviceData.setup();
    },
    decryptData(encryptedData) {
      const secretKey = "my-secret-key"; // clave secreta para el cifrado
      const decryptedData = CryptoJS.AES.decrypt(encryptedData, secretKey);
      return decryptedData.toString(CryptoJS.enc.Utf8);
    },

   async obtenerReferencia() {
     const sumaCifrada = localStorage.getItem("key");
      const sumaDecifrada = this.decryptData(sumaCifrada);
      const suma = parseFloat(sumaDecifrada);
      this.monto = suma;
      const refe = localStorage.getItem("referencias");
      const monto_ida = suma;
      const nombre = localStorage.getItem("nombre");
      const email = localStorage.getItem("email");
      const apellido = localStorage.getItem("apellido");
      const telefono = localStorage.getItem("telefono");
      const subcorrida_ida_id = localStorage.getItem("subcorrida_id");
      if (refe == null) {
        let json = {
          data: {
            nombre: nombre,
            apellidos: apellido,
            email: email,
            telefono: telefono,
            monto: monto_ida,
            deviceIdHiddenFieldName: this.deviceSessionId,
            subcorrida_ida_id: subcorrida_ida_id
          },
        };
        let config = {
          headers: {
            Accept: "application/vnd.api+json",
            "Content-Type": "application/vnd.api+json",
          },
        };
        let me = this;
        axios.post("/openpay/crearCodigoBarras", json, config).then(function (response) {
            me.transaccion_plataforma_id = response.data.result.order_id;
            me.referencia = response.data.result.payment_method.reference;
            me.linea = response.data.result.linea;
            me.ventaBoletos();
            localStorage.setItem("referencias", me.referencia);
            me.imagen = response.data.result.payment_method.barcode_url;
            const fechaIso = response.data.result.due_date;
            const fecha = new Date(fechaIso);
            const meses = [
              "enero",
              "febrero",
              "marzo",
              "abril",
              "mayo",
              "junio",
              "julio",
              "agosto",
              "septiembre",
              "octubre",
              "noviembre",
              "diciembre",
            ];
            const dia = fecha.getDate();
            const mes = meses[fecha.getMonth()];
            const anio = fecha.getFullYear();
            const hora = fecha.toLocaleTimeString();
            me.fecha_vencimiento = `${dia} de ${mes} del ${anio}, a las ${hora}`;
            const fechaISO_creacion = response.data.result.creation_date;
            const fecha_creacion = new Date(fechaISO_creacion);
            const dia_creacion = fecha_creacion.getDate();
            const mes_creacion = meses[fecha_creacion.getMonth()];
            const anio_creacion = fecha_creacion.getFullYear();
            const hora_creacion = fecha_creacion.toLocaleTimeString();
            me.fecha_creacion = `${dia_creacion} de ${mes_creacion} del ${anio_creacion}, a las ${hora_creacion}`;
            localStorage.setItem("url", me.imagen);
            localStorage.setItem("fecha_vencimiento", me.fecha_vencimiento);
            localStorage.setItem("fecha_creacion", me.fecha_creacion);
            me.cargando = false;
            me.validacion = true;
        }).catch(function (error) {
          me.cargando = false;
          me.mensaje_error = error.response.data.message
          if(error.response.data.message != undefined){
            me.$notify({
              group: "apolo",
              type: "error",
              title: "Notificación Apolo Platinum",
              text: error.response.data.message,
              duration: -1
            });
          }
          if(error.response.data.errors != undefined){
              me.$notify({
                group: "apolo",
                  type: "error",
                  title: "Notificación Apolo Platinum",
                  text: error.response.data.errors[0].detail
              });
          }
          return;
        });

      } else {
        this.referencia = localStorage.getItem("referencias");
        this.imagen = localStorage.getItem("url");
        this.fecha_creacion = localStorage.getItem("fecha_creacion");
        this.fecha_vencimiento = localStorage.getItem("fecha_vencimiento");
        this.cargando = false;
        this.monto = suma;
      }
    },
     async ventaBoletos() {
      const value = localStorage.getItem("value");
      if (value == 0) {
        //el viaje solo es de ida y vuelta
        this.ventaVuelta();
      } else {
        //el viaje es de ida
        this.ventaIda();
        
      }
    },

      async ventaIda() {
        const monto = localStorage.getItem("monto_ida");
        const boletos = JSON.parse(this.boletos_ida);
        if (boletos && Array.isArray(boletos)) {
          for (let i = 0; i < boletos.length; i++) {
            
          }
        }

        const json2 = {
          data: {
            type: "venta_boleto",
            attributes: {
              token_id: localStorage.getItem("token_id"),
              corrida_ida_id: localStorage.getItem("corrida_ida_id"),
              subcorrida_ida_id: localStorage.getItem("subcorrida_id"),
              ruta_ida_id: localStorage.getItem("ruta_ida_id"),
              fecha_salida_ida: localStorage.getItem("fecha_ida_hora"),
              es_redondo: false,
              nombre: localStorage.getItem("nombre"),
              apellido: localStorage.getItem("apellido"),
              email: localStorage.getItem("email"),
              telefono: localStorage.getItem("telefono"),
              enlinea: localStorage.getItem("monto_ida"),
              plataforma: 2,
              transaccion_plataforma_id: this.transaccion_plataforma_id,
              asientos: boletos,
              corrida_vuelta_id: null,
              subcorrida_vuelta_id: null,
              ruta_vuelta_id: null,
              fecha_salida_vuelta: null,
            },
          },
        };
        let config = {
          headers: {
            Accept: "application/vnd.api+json",
            "Content-Type": "application/vnd.api+json",
          },
        };
        axios
          .post("/venta", json2, config)
          .then((data) => {
            if (data.status == "200") {
              localStorage.setItem("venta_exitosa", true);
              data.data.result.boletos.forEach((boleto) => {
                this.boletos_reservados.push(boleto.id);
              });
              this.boletos_reservados_string = this.boletos_reservados.join(',');
              //console.log("Se hizo con exito LOS BOLETOSSS");
            }
            if (data.status == "422") {
              localStorage.setItem("venta_exitosa", false);
              //console.log("Problema 422");
            }
          })
          .catch((error) => {
            localStorage.setItem("venta_exitosa", false);
            console.log(error.response.data.errors);
          });
      
    },

     async ventaVuelta() {
        const monto = localStorage.getItem("monto_ida");
        const boletos = JSON.parse(this.boletos_ida);
        
        if (boletos && Array.isArray(boletos)) {
          for (let i = 0; i < boletos.length; i++) {
            //console.log(boletos);
            //console.log(boletos[i].numero);
          }
        }

        const json2 = {
          data: {
            type: "venta_boleto",
            attributes: {
              token_id: localStorage.getItem("token_id"),
              corrida_ida_id: localStorage.getItem("corrida_ida_id"),
              subcorrida_ida_id: localStorage.getItem("subcorrida_id"),
              ruta_ida_id: localStorage.getItem("ruta_ida_id"),
              fecha_salida_ida: localStorage.getItem("fecha_ida_hora"),
              es_redondo: true,
              nombre: localStorage.getItem("nombre"),
              apellido: localStorage.getItem("apellido"),
              email: localStorage.getItem("email"),
              telefono: localStorage.getItem("telefono"),
              enlinea: localStorage.getItem("monto_ida"),
              plataforma: 2,
              transaccion_plataforma_id: this.transaccion_plataforma_id,
              asientos: this.boletos_combinados,
              corrida_vuelta_id: localStorage.getItem("corrida_vuelta_id"),
              subcorrida_vuelta_id: localStorage.getItem("subcorrida_vuelta_id"),
              ruta_vuelta_id: localStorage.getItem("ruta_vuelta_id"),
              fecha_salida_vuelta: localStorage.getItem("fecha_vuelta_hora"),
            },
          },
        };
        let config = {
          headers: {
            Accept: "application/vnd.api+json",
            "Content-Type": "application/vnd.api+json",
          },
        };
        //console.log(json2);
        axios
          .post("/venta", json2, config)
          .then((data) => {
            if (data.status == "200") {
              localStorage.setItem("venta_exitosa", true);
              //console.log(data);
            }
          })
          .catch((error) => {
            localStorage.setItem("venta_exitosa", false);
            //console.log(error);
            //console.log(error.response.data.errors);
          });
      
    },
    async fechaCreacion() {
      const date = new Date(this.fecha_vencimiento);
      return date.toLocaleDateString("es-ES", {
        weekday: "long",
        year: "numeric",
        month: "long",
        day: "numeric",
      });
    },
  },
};
</script>

<style>
@media screen and (max-width: 600px) {
  .titulo {
    font-family: "Montserrat";
    font-size: 26px;
    font-weight: bold;
    margin-left: 1%;
    margin-top: 8%;
  }
  .atras {
    margin-top: 10%;
    margin-left: 3%;
  }
  h3 {
  font-size: 25px;
  margin: 15px 0 0 0;
}
h4 {
  font-size: 17px;
  margin: 8px 0;
}
.whitepaper {
  background-color: #fff;
  width: 400px;
  height: auto;
  margin: 0 auto;
  border: #d8d8d8 1px solid;
}
.Header {
  clear: both;
  float: left;
  width: 84%;
  margin: 4% 8% 4% 8%;
}
.Logo_empresa img {
  width: 190px;
  float: left;
}
.Logo_paynet {
  float: right;
  margin-top: 3px;
}
.Logo_paynet div {
  font-size: 20px;
  font-weight: lighter;
  display: block;
  float: left;
  margin: 10px 12px 0 0;
}
.Logo_paynet img {
  width: 130px;
  float: left;
}
.Data {
  width: 100%;
  clear: both;
  float: left;
}
.DT-margin {
  margin: 15px 0;
  display: block;
  float: left;
  width: 100%;
  clear: both;
}
.Big_Bullet {
  width: 40px;
  float: left;
  margin-right: 24px;
}
.Big_Bullet span,
.col2 {
  background-color: #d31e26;
}
.Big_Bullet span {
  width: 100%;
  height: 55px;
  display: block;
}
.col1 {
  width: 350px;
  float: left;
}
.col1 span {
  font-size: 14px;
  clear: both;
  display: block;
  margin: 5px 0;
}
.col1 small {
  font-size: 12px;
  width: 320px;
  display: block;
}
.col2 {
  width: 358px;
  float: right;
  color: #fff;
  padding: 40px 0 40px 40px;
}
.col2 h1 {
  margin: 0;
  padding: 0;
  font-size: 60px;
}
.col2 h1 span {
  font-size: 45px;
}
.col2 h1 small {
  font-size: 20px;
}
.col2 h2 {
  margin: 0;
  font-size: 22px;
  font-weight: lighter;
}
.S-margin {
  padding-left: 80px;
}

.Table-Data {
  margin: 20px 0 0 0;
  clear: both;
  width: 107.5%;
  display: block;
  float: left;
}
.table-row {
  float: left;
  width: 93%;
  padding: 0 8.5%;
}
.table-row div {
  font-size: 18px;
  float: left;
  width: 180px;
  padding: 15px 0;
}
.table-row span {
  font-size: 18px;
  float: left;
  border-left: 3px solid #fff;
  padding: 15px 0 15px 30px;
}
.color1 {
  background-color: #f3f3f3;
}
.color2 {
  background-color: #ebebeb;
}

.col1 ol,
.Col2 ol {
  font-size: 12px;
  width: 290px;
  padding-left: 20px;
}
.col1 li,
.Col2 li {
  padding: 5px 0;
  line-height: 16px;
}
.logos-tiendas {
  clear: both;
  float: left;
  width: 92%;
  padding: 10px 0 10px 8%;
  border-top: 1px solid #ededed;
  border-bottom: 1px solid #ededed;
  margin: 20px 0 0 0;
}
.logos-tiendas div {
  float: left;
  margin-right: 50px;
}
.logos-tiendas small {
  font-size: 11px;
  margin-left: 20px;
  float: left;
}
.logos-tiendas ul {
  margin: 0;
  list-style: none;
  padding: 0;
  width: 480px;
  float: left;
}
.logos-tiendas li {
  float: left;
  margin: 10px 10px 0 10px;
}

.Powered {
  width: 100%;
  float: left;
  margin-top: 18px;
}
.Powered img {
  margin-left: 65px;
  margin-right: 290px;
}
.Powered a {
  border-radius: 6px;
  background-color: #0075f0;
  padding: 7px 13px;
  color: #fff;
  font-size: 16px;
  font-weight: normal;
  text-decoration: none;
  margin: 10px;
}
.Powered a:hover {
  background-color: #009bff;
}

.note {
  font-size: 12px;
}
.tienda {
  font-weight: 600;
  height: 90px;
  width: 190px;
  float: right;
  margin-top: -100px;
  margin-left: 520px;
  font-size: 16px;
  line-height: 22px;
}
.escaner {
  line-height: 20px;
}
.comision {
  text-align: left;
  font-size: 14px;
  line-height: 18px;
}
.total {
  margin-left: -55%;
}
.precio {
  margin-top: 5%;
}
.realizarPago {
  font-weight: 600;
  text-align: left;
  line-height: 18px;
}
.cajero {
  font-weight: 600;
  text-align: left;
  line-height: 18px;
}
.spinner-container {
  text-align: center;
}
.spinner {
  width: 50px;
  height: 50px;
  border: 8px solid #f3f3f3;
  border-top: 8px solid #3498db;
  border-radius: 50%;
  animation: spin 0.8s linear infinite;
  margin-left: 45%;
  margin-top: 40%;
}
.spinner-text {
  margin: 20px 0;
  font-size: 20px;
  color: #333;
}
@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

}

@media screen and (min-width: 601px) {
body {
  margin: 0;
  font-family: Helvetica, Arial, sans-serf;
}

h3 {
  font-size: 25px;
  margin: 15px 0 0 0;
}
h4 {
  font-size: 17px;
  margin: 8px 0;
}
.whitepaper {
  background-color: #fff;
  width: 812px;
  height: 1503px;
  margin: 0 auto;
  border: #d8d8d8 1px solid;
}
.Header {
  clear: both;
  float: left;
  width: 84%;
  margin: 4% 8% 4% 8%;
}
.Logo_empresa img {
  width: 190px;
  float: left;
}
.Logo_paynet {
  float: right;
  margin-top: 3px;
}
.Logo_paynet div {
  font-size: 20px;
  font-weight: lighter;
  display: block;
  float: left;
  margin: 10px 12px 0 0;
}
.Logo_paynet img {
  width: 130px;
  float: left;
}
.Data {
  width: 100%;
  clear: both;
  float: left;
}
.DT-margin {
  margin: 15px 0;
  display: block;
  float: left;
  width: 100%;
  clear: both;
}
.Big_Bullet {
  width: 40px;
  float: left;
  margin-right: 24px;
}
.Big_Bullet span,
.col2 {
  background-color: #d31e26;
}
.Big_Bullet span {
  width: 100%;
  height: 55px;
  display: block;
}
.col1 {
  width: 350px;
  float: left;
}
.col1 span {
  font-size: 14px;
  clear: both;
  display: block;
  margin: 5px 0;
}
.col1 small {
  font-size: 12px;
  width: 320px;
  display: block;
  margin-bottom: 12px;
}
.col2 {
  width: 358px;
  float: right;
  color: #fff;
  padding: 40px 0 40px 40px;
}
.col2 h1 {
  margin: 0;
  padding: 0;
  font-size: 60px;
}
.col2 h1 span {
  font-size: 45px;
}
.col2 h1 small {
  font-size: 20px;
}
.col2 h2 {
  margin: 0;
  font-size: 22px;
  font-weight: lighter;
}
.S-margin {
  padding-left: 80px;
}

.Table-Data {
  margin: 20px 0 0 0;
  clear: both;
  width: 107.5%;
  display: block;
  float: left;
}
.table-row {
  float: left;
  width: 93%;
  padding: 0 8.5%;
}
.table-row div {
  font-size: 18px;
  float: left;
  width: 180px;
  padding: 15px 0;
}
.table-row span {
  font-size: 18px;
  float: left;
  border-left: 3px solid #fff;
  padding: 15px 0 15px 30px;
}
.color1 {
  background-color: #f3f3f3;
}
.color2 {
  background-color: #ebebeb;
}

.col1 ol,
.Col2 ol {
  font-size: 12px;
  width: 290px;
  padding-left: 20px;
}
.col1 li,
.Col2 li {
  padding: 5px 0;
  line-height: 16px;
}
.logos-tiendas {
  clear: both;
  float: left;
  width: 92%;
  padding: 10px 0 10px 8%;
  border-top: 1px solid #ededed;
  border-bottom: 1px solid #ededed;
  margin: 20px 0 0 0;
}
.logos-tiendas div {
  float: left;
  margin-right: 50px;
}
.logos-tiendas small {
  font-size: 11px;
  margin-left: 20px;
  float: left;
}
.logos-tiendas ul {
  margin: 0;
  list-style: none;
  padding: 0;
  width: 480px;
  float: left;
}
.logos-tiendas li {
  float: left;
  margin: 10px 10px 0 10px;
}

.Powered {
  width: 100%;
  float: left;
  margin-top: 25px;
}
.Powered img {
  margin-left: 65px;
  margin-right: 290px;
}
.Powered a {
  border-radius: 6px;
  background-color: #0075f0;
  padding: 7px 13px;
  color: #fff;
  font-size: 16px;
  font-weight: normal;
  text-decoration: none;
  margin: 10px;
}
.Powered a:hover {
  background-color: #009bff;
}

.note {
  font-size: 12px;
}
.tienda {
  font-weight: 600;
  height: 90px;
  width: 190px;
  float: right;
  margin-top: -100px;
  margin-left: 520px;
  font-size: 16px;
  line-height: 22px;
}
.escaner {
  line-height: 20px;
}
.comision {
  text-align: left;
  font-size: 14px;
  line-height: 18px;
}
.total {
  margin-left: -55%;
}
.precio {
  margin-top: 5%;
}
.realizarPago {
  font-weight: 600;
  text-align: left;
  line-height: 18px;
}
.cajero {
  font-weight: 600;
  text-align: left;
  line-height: 18px;
}
.spinner-container {
  text-align: center;
}
.spinner {
  width: 50px;
  height: 50px;
  border: 8px solid #f3f3f3;
  border-top: 8px solid #3498db;
  border-radius: 50%;
  animation: spin 0.8s linear infinite;
  margin-left: 45%;
  margin-top: 40%;
}
.spinner-text {
  margin: 20px 0;
  font-size: 20px;
  color: #333;
}
@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
}
</style>