<template>
  <div>
    <Stepper7 class="active" Text1="Paso1" />
       <div v-if="msg" class="alert alert-primary" role="alert">
        {{ msg_info }}
      </div>
      <!--OPENPAY-->
      <div class="container place-items-center text-center mt-12">
        <div class="row flex items-center">
          <div>
            <img v-if="metodo == 'openpay'" class="h-auto max-w-xs" src="img/open.png" />
            <img v-if="metodo == 'paypal'" class="h-auto max-w-sm" src="img/paypal.png" />
          </div>
        </div>
        <div class="row">
          <div v-if="msg_info" class="alert alert-primary" role="alert">
            {{ msg_info }}
          </div>
        </div>
      </div>
      <div class="Facturar">
        <button @click="eliminarData();">
            <App-button :ancho="true" buttonText="Ir a la página de Inicio" />
        </button>
      </div>


  </div>

</template>
<style scoped lang="less">
@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800&display=swap");

@media screen and (max-width: 665px) {
.alert {
  padding: 5px;
  border: 5px solid transparent;
  border-radius: 10px;
}
.alert-primary {
  color: #004085;
  background-color: #cce5ff;
  border-color: #b8daff;
}
.alert-secondary {
  color: #850000;
  background-color: #ffcccc;
  border-color: #ffb8b8;
}
}

@media screen and (min-width: 666px) {
.alert {
  padding: 20px;
  border: 5px solid transparent;
  border-radius: 10px;
}
.alert-primary {
  color: #004085;
  background-color: #cce5ff;
  border-color: #b8daff;
}
.alert-secondary {
  color: #850000;
  background-color: #ffcccc;
  border-color: #ffb8b8;
}
}
</style>

<script>
import axios from "axios";
import Stepper7 from "@/components/Stepper/Stepper7.vue";
import Titulo from "@/components/title.vue";
import Subtitulo3 from "@/components/SubTitle3.vue";
import AppButton from "@/components/ButtonPrimary.vue";
import Back from "@/components/Back.vue";

export default {
  name: "HomeView",
  props:{
    type: { required: false },
    id: { required: false }
  },
  data: () => ({
    msg: false,
    msg_info: null,
    order_id: null,
    metodo: null
  }),
  components: {
    Stepper7,
    Titulo,
    Subtitulo3,
    Back,
    AppButton,
  },
  mounted() {
    //console.log(this.type)
    if(this.type === 'paypal'){
      this.metodo = 'paypal';
      this.msg_info = 'Pago exitoso';
    }else{
      this.metodo = 'openpay';
    }

    this.order_id = new URL(location.href).searchParams.get('id');
    
    if(this.order_id){
      this.getStatusCargo();
    }
  },
  methods: {
    async getStatusCargo(){
      let json = {
        data: {
          attributes: {
            metodo: 'openpay',
            id: this.order_id
          }
        },
      };
      let config = {
        headers: {
          Accept: "application/vnd.api+json",
          "Content-Type": "application/vnd.api+json",
        },
      };

      let me = this;

      axios.post("/statuscargo", json, config).then(function (response) {
        me.msg_info = response.data.message;
      }).catch(function (error) {
        if(error.response.data.errors){
            me.$notify({
              group: "apolo",
              type: "error",
              title: "Notificación Apolo Platinum",
              text: error.response.data.errors[0].detail,
              duration: -1
            });
        }else{
          me.$notify({
            group: "apolo",
            type: "error",
            title: "Notificación Apolo Platinum",
            text: error.response.data.message,
            duration: -1
          });
        }
      });
    },
    eliminarData() {
     this.$router.push('/') 
    } 
  }
};
</script>